// @flow

/* eslint-disable max-len */
import { useEffect, useState } from 'react'

export const useDataLoader = (url: string, skip: boolean = false) => {
  const [ data, setData ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(true)
  let cancelled = false
  useEffect(() => {
    setIsLoading(true)
    if (skip) {
      setIsLoading(false)
      return
    }
    fetch(url)
      .then(response => Promise.all(
        [ response.headers.get('x-wp-total'), response.json() ]
      ))
      .then(data => {
        if (cancelled) {
          return
        }
        const [ total, result ] = data
        setIsLoading(false)
        setData({
          result,
          total: parseInt(total, 10)
        })
      })
      .catch(e => {
        if (cancelled) {
          return
        }
        setIsLoading(false)
        // console.log(e)
      })
    return () => {
      cancelled = true
    }
  }, [ url, skip ])
  return {
    data,
    isLoading
  }
}
