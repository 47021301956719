// @flow

import React from 'react'
import classNames from 'classnames'
import { applyStyling } from '../../lib/styling'
import { Link } from 'gatsby-plugin-intl'
import baseStyles from './Teaser.scss'

type Props = {
  styles: Object,
  title?: string,
  text?: string,
  textComponent?: any,
  info?: string,
  date?: string,
  image?: Object,
  link?: string
}

const Teaser = ({
  styles, title, text, info, date, image, link, textComponent
}: Props) => {
  const ItemContent = () => (
    <div title={link === '/kontakt' ? 'Kontakt aufnehmen' : title}>
      <div className={styles.imageWrap}>
        <img className={styles.image} src={image} />
      </div>

      <div className={styles.textWrap}>
        <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }}/>
        {(info || date) &&
        <div className={styles.info}>
          {info}
          {date && <span className={styles.date}>{date}</span>}
        </div>
        }
        {text && <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }}/>}
        <div className={link && styles.readMore}>
          {textComponent && <div className={styles.text}>{textComponent}</div>}
          {link && link !== '/kontakt' && <span className={styles.link}>Mehr erfahren</span>}
        </div>
      </div>
    </div>
  )

  return (
    link ? (
      link.indexOf('http') === 0 ? (
        <a
          href={link} className={classNames(styles.root, styles['with-link'])} target="_blank" rel="noopener noreferrer"
        >
          <ItemContent/>
        </a>
      ) :
        <Link to={link} className={classNames(styles.root, styles['with-link'])}>
          <ItemContent/>
        </Link>
    )
      :
      <div className={styles.root}>
        <ItemContent/>
      </div>
  )
}

export default applyStyling(baseStyles)(Teaser)
