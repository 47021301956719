// @flow

/* eslint-disable max-len */
import React from 'react'

/* $FlowFixMe: ignore */
import { encode } from 'querystring'
import { useDataLoader } from './hooks'
import { createGeneralParams } from './helpers/params'

export const withNewsFacets = (WrappedComponent: any) => {
  const NewsFacetsAwareComponent = (props: Object) => {
    /* eslint-disable-next-line */
    const url = '/wp-json/wp/v2/news_facets'
    const { data, isLoading } = useDataLoader(url)
    return (
      <WrappedComponent {...props} facets={data && data.result} isLoading={isLoading}/>
    )
  }
  return NewsFacetsAwareComponent
}

// types - array of post types
export const withPostList = (types: Array<string> = [ 'news' ]) => (WrappedComponent: any) => {
  const PostListAwareComponent = (props: Object) => {
    const { search, skip } = props

    /* eslint-disable-next-line */
    const params = Object.assign({}, createGeneralParams(search))
    if (search.category) {
      params['categories'] = search.category
    }
    if (search.period) {
      params['after'] = `${search.period}-01-01T00:00:00.000Z`
      params['before'] = `${search.period}-12-31T23:59:59.999Z`
    }
    const postTypes = types.map(type => `type[]=${type}`).join('&')
    const url = `/wp-json/wp/v2/posts?${encode(params)}&_embed=1&${postTypes}`

    const { data, isLoading } = useDataLoader(url, skip)

    return (
      <WrappedComponent {...props} posts={data} isLoading={isLoading}/>
    )
  }

  return PostListAwareComponent
}
