// @flow
import React, { Component } from 'react'
import _ from 'lodash'
import { withQuery, defaultValues } from '../../animalSearch'
import WithHandlersWrapper, { type WithAnimalSearchHandlersProps }
  from '../../components/AnimalSearch/WithHandlersWrapper'
import type { WithQueryProps } from '../../index'

export type WithAnimalSearchQueryWrapperProps = WithQueryProps & WithAnimalSearchHandlersProps & {
  onResetFilters: Function
}

const withQueryWrapper = (InnerComponent: any) => {
  class Wrapper extends Component<*> {
    onResetFilters = () => {
      this.props.reset()
    }

    onPartialResetFilters = (keepKeys = []) => {
      this.props.setStateValue({ ...defaultValues, ..._.pick(this.props.values, keepKeys) })
    }

    setSearchValue = (value: Object) => {
      this.props.setStateValue({ ...this.props.values, ...value })
    }

    updateStateValue = (name, value) => {
      if (name !== 'page') {
        this.props.updateStateValue('page', 0)
      }
      this.props.updateStateValue(name, value)
    }

    render() {
      const { values, ...rest } = this.props
      return (
        <WithHandlersWrapper animalSearch={values} updateStateValue={this.updateStateValue}>
          {props => (
            <InnerComponent
              {...props}
              {...rest}
              updateStateValue={this.updateStateValue}
              setSearchValue={this.setSearchValue}
              onResetFilters={this.onResetFilters}
              onPartialResetFilters={this.onPartialResetFilters}
            />
          )}
        </WithHandlersWrapper>
      )
    }
  }

  return withQuery(Wrapper)
}

export default withQueryWrapper
