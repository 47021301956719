// @flow

import React, { memo, useCallback, useContext } from 'react'
import { navigate } from '@reach/router'
import { injectIntl, Link } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import { PageContext } from '../../provider/page'
import { applyStyling } from '../../lib/styling'
// import Device from '../../components/basic/Device'
import { Container } from '../../components/basic/Grid'
// import Button from '../../components/basic/Button'
import baseStyles from './Filter.scss'
import { compose, withProps } from 'recompose'
import _get from 'lodash/get'
import withQueryWrapper from '../../state/enchancers/animalSearch/withQueryWrapper'
import {
  ANIMAL_TYPE_BIRDS,
  ANIMAL_TYPE_CATS,
  ANIMAL_TYPE_DOGS,
  ANIMAL_TYPE_SMALL,
  defaultValues
} from '../../state/animalSearch'

type Props = {
  styles: Object,
  animalSearch: Object,
  onChangeType: Function,
  btn?: String,
  showDogs?: boolean,
  showCats?: boolean,
  showBirds?: boolean,
  showSmall?: boolean
}

const Filter = memo(({
  styles, btn, onChangeType, animalSearch,
  showDogs = true, showCats = true, showBirds = true, showSmall = true
}: Props) => {
  const onDog = useCallback(() => onChangeType(ANIMAL_TYPE_DOGS), [ onChangeType ])
  const onCat = useCallback(() => onChangeType(ANIMAL_TYPE_CATS), [ onChangeType ])
  const onBird = useCallback(() => onChangeType(ANIMAL_TYPE_BIRDS), [ onChangeType ])
  const onSmall = useCallback(() => onChangeType(ANIMAL_TYPE_SMALL), [ onChangeType ])
  const { type } = animalSearch

  return (
    <div className={styles.root}>
      <Container className={styles.wrap}>
        <ul className={styles.navList}>
          {showDogs &&
          <li className={styles.navItem}>
            <div
              onClick={onDog} className={classNames(styles.navLink, type === ANIMAL_TYPE_DOGS && styles['is-active'])}
            >
              <span className={`${styles.navIcon} icon-dogs`} />
              Hunde
            </div>
          </li>}
          {showCats &&
          <li className={styles.navItem}>
            <div
              onClick={onCat} className={classNames(styles.navLink, type === ANIMAL_TYPE_CATS && styles['is-active'])}
            >
              <span className={`${styles.navIcon} icon-cat`} />
              Katzen
            </div>
          </li>}
          {showBirds &&
          <li className={styles.navItem}>
            <div
              onClick={onBird} className={classNames(styles.navLink, type === ANIMAL_TYPE_BIRDS && styles['is-active'])}
            >
              <span className={`${styles.navIcon} icon-bird`} />
              Vögel
            </div>
          </li>}
          {showSmall &&
          <li className={styles.navItem}>
            <div
              onClick={onSmall}
              className={classNames(styles.navLink, type === ANIMAL_TYPE_SMALL && styles['is-active'])}
            >
              <span className={`${styles.navIcon} ${showSmall && !showBirds ? 'icon-bird' : 'icon-small-animals'}`}/>
              Kleintiere
            </div>
          </li>}
          <li className={styles.navItem}>
            <Link
              to="/tiervermittlung-vermisste-tiere"
              className={styles.navLink} activeClassName={styles['is-active']}
            >
              <span className={`${styles.navIcon} icon-missed`}/>
              Vermisst
            </Link>
          </li>
        </ul>
        {/* <Button styles={styles.searchBtn} size="lg">
          {btn ? btn :
            <>
              <Device tablet desktop>finden</Device>
              <Device phone>direkt finden</Device>
            </>
          }
        </Button>*/}
      </Container>
    </div>
  )
})

Filter.displayName = 'Filter'

const WrappedFilter = compose(
  injectIntl,
  withQueryWrapper,
  withProps(ownProps => ({
    onChangeType: type => {
      const { page } = ownProps
      const pageSlug = _get(page, 'page.slug')
      const pathname = [
        'tiervermittlung-vermisste-tiere-vermisste-tiere-liste', 'lost-pets',
        'tiervermittlung-vermittlung', 'animal-mediations',
        'tiervermittlung-private-tiervermittlung-private-tiervermittlung-liste', 'private-pets'
      ].indexOf(pageSlug) === -1 ? 'tiervermittlung-vermittlung' : pageSlug

      navigate(
        `${pathname}${type !== defaultValues.type ? ('?as.type=' + type) : ''}`,
        { replace: true }
      )
      setTimeout(() => {
        ownProps.setSearchValue({ ...defaultValues, type })
      }, 100)
    }
  })),
  applyStyling(baseStyles)
)(Filter)

const FilterComponent = (props: Object) => {
  const page = useContext(PageContext)
  return (
    <WrappedFilter {...props} page={page}/>
  )
}

export default FilterComponent
