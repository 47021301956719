// @flow

import React, { type AbstractComponent } from 'react'
import { QueryContainer } from 'react-history-query'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { GlobalStateProvider } from '@fcse/param-converter'
import { Form as AnimalSearch } from '../state/animalSearch'
import { Form as NewsSearch } from '../state/newsSearch'
import { Form as GlobalSearch } from '../state/globalSearch'
import LocationContext from '../lib/locationContext'
import _get from 'lodash/get'

export const withSearch = <Props: {}>(
  Component: AbstractComponent<Props>
): AbstractComponent<Props> => {
  return function WrapperComponent(props: Props) {
    const history = (typeof window === 'undefined') ?
      createMemoryHistory({ initialEntries: [ _get(props, 'location.pathname') ] }) :
      createBrowserHistory()

    return (
      <LocationContext.Provider value={_get(props, 'location')}>
        <QueryContainer history={history}>
          <GlobalStateProvider>
            <AnimalSearch>
              <NewsSearch>
                <GlobalSearch>
                  <Component {...props} />
                </GlobalSearch>
              </NewsSearch>
            </AnimalSearch>
          </GlobalStateProvider>
        </QueryContainer>
      </LocationContext.Provider>
    )
  }
}
