/* eslint-disable max-len */
import React from 'react'
import { compose, withProps } from 'recompose'
import _get from 'lodash/get'
// import { Link, injectIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import HeroTeaser from '../components/HeroTeaser'
import Slider from '../components/Slider'
import BackToTop from '../components/BackToTop'
import Filter from '../components/Filter'
import { Container } from '../components/basic/Grid'
import Teasers from '../modules/Teasers'
import SEO from '../components/seo'
import { withSearch } from '../enhancers/withSearch'
import withQueryWrapper from '../state/enchancers/animalSearch/withQueryWrapper'
import LocationContext from 'lib/locationContext'
import { withPageBySlug } from 'provider/page'
import { withPostList } from 'provider/post'
import moment from 'moment'
import { shorten } from 'helpers/misc'

const IndexPage = ({
  intl,
  location,
  imageLandscape,
  imageLandscape2x,
  imageLandscape3x,
  imageLandscape4x,
  content,
  heroTeaserTitle,
  heroTeaserSubtitle,
  posts,
  page,
  meta,
  isLoading
}: Object) => {
  const newsItems = posts && posts.result && posts.result.map(item => ({
    id: item.id,
    link: `/${item.slug}`,
    image: _get(item, 'featured_image_src_medium'),
    title: _get(item, 'title.rendered'),
    info: _get(item, '_embedded.wp:term.0.0.name'),
    date: moment(item.date).format('DD.MM.YYYY'),
    text: shorten(
      _get(item, 'acf.brief_description') || _get(item, 'content.rendered', ''),
      100)
  })) || []
  const numberOfArticles = parseInt(page && page.acf && page.acf['number_of_articles'] || 10, 10)

  return (
    <Layout footer={<Footer meta={meta}/>} isLoading={isLoading}>
      <SEO title="Homepage" />
      <LocationContext.Provider value={location}>
        <Header withTeaser meta={meta}/>
      </LocationContext.Provider>
      <HeroTeaser
        imageLandscape={imageLandscape}
        imageLandscape2x={imageLandscape2x}
        imageLandscape3x={imageLandscape3x}
        imageLandscape4x={imageLandscape4x}
        title={heroTeaserTitle}
        subtitle={heroTeaserSubtitle}
      />
      {page && page.acf && page.acf['show_search_navigation'] && <Filter />}

      {!isLoading &&
      <Slider
        title="Heute aktuell"
        items={newsItems.slice(0, numberOfArticles)}
      />}

      <div dangerouslySetInnerHTML={{ __html: content }}/>

      {page && page.acf && page.acf.teasers &&
      <Teasers teasers={page.acf.teasers}/>}

      <Container>
        <SEO title="Home" keywords={[ 'tierheim', 'dusseldorf', 'animals' ]} />
      </Container>

      <BackToTop />
    </Layout>
  )
}

export default compose(
  withPageBySlug('index'),
  withSearch,
  withQueryWrapper,
  withProps(ownProps => ({
    search: {
      sortBy: 'new',
      perPage: 10
    }
  })),
  withPostList([ 'articles' ]),
)(IndexPage)
