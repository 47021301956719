/* eslint-disable max-len */
import React, { useMemo } from 'react'
import _get from 'lodash/get'

import TinaTeaser from '../../components/TinaTeaser'
import ProjectsTeasers from '../../components/ProjectsTeasers'
import NewsTeasers from '../../components/NewsTeasers'
import AlternativeTeasers from '../../components/AlternativeTeasers/'
import { mapTextAndImage, mapWideNews2Items, mapThreeItems, mapWideTeaser } from './mappers'

type Props = {
  teasers: Array<Object>
}

const widgetFactory = (widgetType, teaser, idx) => {
  if (widgetType === 'wide_teaser') {
    return (_get(teaser, 'wide_teaser_elements', []) || [])
      .map(data => <TinaTeaser data={mapWideTeaser(data)} key={idx}/>)
  }
  if (widgetType === 'three_items') {
    return (_get(teaser, 'three_items_elements', []) || [])
      .map(data => <ProjectsTeasers data={mapThreeItems(data)} key={idx}/>)
  }
  if (widgetType === 'wide_news_2_items') {
    return (_get(teaser, 'wide_news_2_items', []) || [])
      .map(data => <NewsTeasers data={mapWideNews2Items(data)} key={idx}/>)
  }
  if (widgetType === 'text_and_image') {
    return (
      <AlternativeTeasers key={idx} items={mapTextAndImage(teaser)}/>
    )
  }
  return widgetType
}

const Teasers = (props: Props) => {
  const { teasers } = props
  const content = useMemo(() => {
    return teasers.map((teaser, idx) => {
      const widgetType = _get(teaser, 'widget_type')
      return widgetFactory(widgetType, teaser, idx)
    })
  }, [ teasers ])
  return (
    <>
      {content}
    </>
  )
}

export default Teasers
