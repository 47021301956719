// @flow
/* eslint-disable max-len */
import React, { useMemo } from 'react'
import { applyStyling } from '../../lib/styling'
import _ from 'lodash'
import Teaser from '../../components/Teaser'
import Carousel from '../../components/basic/Carousel'
import Device from '../../components/basic/Device'
import { Container } from '../../components/basic/Grid'

import baseStyles from './Slider.scss'

type Props = {
  styles: Object,
  title: string,
  items: Array<Object>
}

const Slider = ({
  title,
  styles,
  items
}: Props) => {
  const sliderItems = useMemo(() => {
    return _.map(items, item => (
      <Teaser
        key={item.id}
        styles={styles.teaser}
        link={item.link}
        image={item.image}
        title={item.title}
        info={item.info}
        date={item.date}
        text={item.text}
      />
    ))
  }, [ items ])

  return (
    <Container className={styles.root}>
      <h2 className={styles.title}>
        {title}
      </h2>
      <Device tablet desktop>
        <Carousel
          settings={{
            swipe: true,
            lazyLoad: 'progressive',
            infinite: true,
            responsive: [
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 9999,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  swipe: false
                }
              }
            ]
          }}
        >
          {sliderItems}
        </Carousel>
      </Device>
      <Device phone>
        {sliderItems}
      </Device>
    </Container>
  )
}

export default applyStyling(baseStyles)(Slider)
