// @flow

import { pm, serializers, queryFormFactory } from '@fcse/param-converter'

const config = {
  query: pm('query', serializers.string),
  period: pm('period', serializers.string),
  category: pm('category', serializers.string),
  sortBy: pm('sortBy', serializers.string),
  perPage: pm('perPage', serializers.number),
  page: pm('page', serializers.number)
}

export const defaultValues = {
  query: '',
  period: '',
  category: '',
  sortBy: 'new',
  page: 0,
  perPage: 10
}

const { Form, FormWithQuery, withQuery, createQueryString }
  = queryFormFactory('newsSearchNS', () => defaultValues, config, null, 'ns') // in case we need a separate namespace


export { Form, FormWithQuery, withQuery, createQueryString }
