// @flow
import React, { useEffect, useState } from 'react'
import _get from 'lodash/get'
// import cache from '../lib/cache'
import { mapAgePhase, mapGender, mapType, mapSize } from './teo'

export const mapBasicPageProps = (page: Object) => {
  return ({
    imageLandscape: _get(page, 'acf.hero_teaser_background.sizes.landscape'),
    imageLandscape2x: _get(page, 'acf.hero_teaser_background.sizes.landscape2x'),
    imageLandscape3x: _get(page, 'acf.hero_teaser_background.sizes.landscape3x'),
    imageLandscape4x: _get(page, 'acf.hero_teaser_background.sizes.landscape4x'),
    heroTeaserTitle: _get(page, 'acf.hero_teaser_title'),
    heroTeaserSubtitle: _get(page, 'acf.hero_teaser_text'),
    title: _get(page, 'title'),
    content: _get(page, 'content')
  })
}

export const mapAnimalDetailsPageProps = (page: Object) => {
  const isTeo = page.page.type === 'animal_data'
  return ({
    item: {
      name: _get(page, 'title'),
      description: isTeo ? _get(page, 'acf.description') : _get(page, 'content'),
      gender: isTeo ? mapGender(_get(page, 'acf.gender')) : _get(page, 'acf.gender'),
      type: isTeo ? mapType(_get(page, 'acf.type')) : _get(page, 'acf.type'),
      size: isTeo ? mapSize(_get(page, 'acf.size')) : _get(page, 'acf.size'),
      agePhase: isTeo ? mapAgePhase(_get(page, 'acf.age_phase')) : _get(page, 'acf.age_phase'),
      photos: isTeo ? (page.acf?.images || []).map(image => image.sizes?.large || image) : _get(page, 'acf.images'),
      contact: {
        email: _get(page, 'acf.contact_email'),
        mobile: _get(page, 'acf.contact_mobile'),
        phone: _get(page, 'acf.contact_phone'),
        street: _get(page, 'acf.contact_street')
      }
    }
  })
}

export const mapMapMarkers = (str: string) => {
  /* $FlowFixMe: ignore */
  return str.split('\n').map((line, idx) => {
    const items = line.split(';')
    const [ lat, lng, tooltipText ] = items
    return ({ lat: parseFloat(lat), lng: parseFloat(lng), tooltipText, id: `marker${idx}` })
  })
}

/* $FlowFixMe: ignore */
export const PageContext = React.createContext(null)

export const withPageBySlug = (pageSlug?: string) => (WrappedComponent: any) => {
  const PageBySlugAwareComponent = (props: Object) => {
    /* eslint-disable-next-line */
    const slug = pageSlug || props['*']
    const url = `/wp-json/wp/v2/pages/?slug=${slug}`
    const [ page, setPage ] = useState(false)
    const [ meta, setMeta ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(true)

    const storeResult = result => {
      if (result && result.length === 2) {
        setPage({
          title: result[0].title?.rendered || '',
          content: result[0].content?.rendered || '',
          acf: result[0].acf,
          date: result[0].date,
          page: result[0]
        })
        setMeta(result[1])
      } else {
        if (result && result.length === 1) {
          setMeta(result[0])
        }
        setPage(null)
      }
    }

    let cancelled = false
    useEffect(() => {
      setPage(false)
      // setPage(false)
      setIsLoading(true)
      const value = false // cache.get(url)
      if (value) {
        storeResult(value)
        setIsLoading(false)
      } else {
        fetch(url)
          .then(data => data.json())
          .then(result => {
            if (cancelled) {
              return
            }
            setIsLoading(false)
            storeResult(result)
            // cache.set(url, result)
          })
          .catch(e => {
            if (cancelled) {
              return
            }
            setIsLoading(false)
          })
      }

      return () => {
        cancelled = true
      }
    }, [ url ])

    return (
      <PageContext.Provider value={page}>
        <WrappedComponent {...props} page={page} meta={meta} {...mapBasicPageProps(page)} isLoading={isLoading}/>
      </PageContext.Provider>
    )
  }

  return PageBySlugAwareComponent
}
