// @flow

import React from 'react'
import { applyStyling } from '../../lib/styling'
import { Container } from '../../components/basic/Grid'
import Button from '../../components/basic/Button'
import baseStyles from './TinaTeaser.scss'
import _get from 'lodash/get'

type Props = {
  styles: Object,
  data: Object
}

const TinaTeaser = ({
  styles, data
}: Props) => {
  const imageLandscape = _get(data, 'background.sizes.landscape')
  const imageLandscape4x = _get(data, 'background.sizes.landscape4x')
  const imageLandscape2x = _get(data, 'background.sizes.landscape2x')
  const imageMobile = _get(data, 'background.sizes.mobile')
  const imageMobile2x = _get(data, 'background.sizes.mobile2x')
  const imageTablet = _get(data, 'background.sizes.tablet')
  const imageTablet2x = _get(data, 'background.sizes.tablet2x')
  return (
    <div className={styles.root}>
      {imageLandscape &&
      <div className={styles.imageWrap}>
        <picture className={styles.imageContainer}>
          <source
            media={'(min-width: 2049px)'}
            srcSet={`${imageLandscape2x} 2x, ${imageLandscape4x} 4x`}
          />
          <source
            media={'(min-width: 1025px)'}
            srcSet={`${imageLandscape} 1x, ${imageLandscape2x} 2x`}
          />
          <source
            media={'(min-width: 768px)'}
            srcSet={`${imageTablet} 1x, ${imageTablet2x} 2x`}
          />
          <source
            media={'(max-width: 767px)'}
            srcSet={`${imageMobile} 1x, ${imageMobile2x} 2x`}
          />
          <img className={styles.image} src={imageLandscape} />
        </picture>
      </div>}

      <div className={styles.headlines}>
        <Container>
          <div className={styles.textWrap}>
            <h2 className={styles.title}>{_get(data, 'title')}</h2>
            <p className={styles.text} dangerouslySetInnerHTML={{ __html: _get(data, 'content') }}/>
            {data.link &&
            <Button
              tag="a"
              href={data.link}
              target="_blank"
              rel="noopener noreferrer"
              iconRight="icon-right"
              styles={styles.moreBtn}
            >
              mehr erfahren
            </Button>}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default applyStyling(baseStyles)(TinaTeaser)
