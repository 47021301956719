// @flow
/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import { Container } from '../../components/basic/Grid'
import AlternativeTeaser from '../../components/AlternativeTeasers/AlternativeTeaser'
import baseStyles from './AlternativeTeasers.scss'

type Props = {
  styles: Object,
  items: Array<Object>
}

const AlternativeTeasers = ({ styles, items }: Props) => (
  <Container className={styles.root}>
    {items.map((item, idx) => (
      <AlternativeTeaser
        key={idx}
        styles={styles.teaser}
        image={item.image}
        title={item.title}
        btnLink={item.link}
        isReversed={item.isReversed}
        text={
          <div dangerouslySetInnerHTML={{ __html: item.text }}/>
        }
      />
    ))}
  </Container>
)

export default applyStyling(baseStyles)(AlternativeTeasers)
