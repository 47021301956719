// @flow
import React from 'react'
// import classNames from 'classnames'
import { applyStyling } from '../../../lib/styling'
import Teaser from '../../Teaser'
import Button from '../../basic/Button/index'
import { Link } from 'gatsby-plugin-intl'
import baseStyles from './AlternativeTeaser.scss'

type Props = {
  styles: Object,
  title?: string,
  text?: string,
  btnLink?: string,
  image?: Object,
  isReversed?: Boolean
}

const AlternativeTeaser = ({
  styles, text, isReversed, btnLink, ...rest
}: Props) => (
  <Teaser
    styles={[ styles.root, isReversed && styles['is-reversed'] ]}
    textComponent={
      <>
        {text}
        <Link to={btnLink} className={styles.btnLink}>
          <Button tag="span" iconRight="icon-right" styles={styles.btn}>mehr erfahren</Button>
        </Link>
      </>
    }
    {...rest}
  />
)

export default applyStyling(baseStyles)(AlternativeTeaser)
