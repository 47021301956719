// @flow

import { ANIMAL_TYPE_CATS, ANIMAL_TYPE_DOGS, ANIMAL_TYPE_SMALL, ANIMAL_TYPE_BIRDS } from './../state/animalSearch'

export const toTeoGender = (gender: string) => {
  if (gender === 'female') {
    return '0'
  }
  if (gender === 'male') {
    return '1'
  }
  return null
}

export const toTeoAgePhase = (agePhase: string) => {
  if (agePhase === 'senior') {
    return '3'
  }
  if (agePhase === 'adult') {
    return '2'
  }
  if (agePhase === 'baby') {
    return '1'
  }
  return null
}

export const toTeoType = (type: string) => {
  if (type === ANIMAL_TYPE_DOGS) {
    return '0'
  }
  if (type === ANIMAL_TYPE_CATS) {
    return '1'
  }
  if (type === ANIMAL_TYPE_SMALL) {
    return '2'
  }
  if (type === ANIMAL_TYPE_BIRDS) {
    return '22'
  }
  return null
}

export const toTeoSize = (size: string) => {
  if (size === 'small') {
    return '1'
  }
  if (size === 'medium') {
    return '2'
  }
  if (size === 'big') {
    return '3'
  } // 0?
  return null
}

export const mapAgePhase = (agePhase: string) => {
  if (agePhase === '3') {
    return 'senior'
  }
  if (agePhase === '2') {
    return 'adult'
  }
  return 'baby'
}

export const mapGender = (gender: string) => {
  if (gender === '0') {
    return 'female'
  }
  if (gender === '1') {
    return 'male'
  }
  return 'unknown'
}

export const mapType = (type: string) => {
  if (type === '0') {
    return ANIMAL_TYPE_DOGS
  }
  if (type === '1') {
    return ANIMAL_TYPE_CATS
  }
  if (type === '22') {
    return ANIMAL_TYPE_BIRDS
  }
  return ANIMAL_TYPE_SMALL
}

export const mapSize = (size: string) => {
  if (size === '3') {
    return 'big'
  }
  if (size === '2') {
    return 'medium'
  }
  if (size === '1') {
    return 'small'
  }
  // 0?
  return 'small'
}

export const mapAnimalData = (item: Object) => {
  return ({
    id: item.id,
    agePhase: mapAgePhase(item.acf?.['age_phase'] || ''),
    description: item.acf?.description || '',
    castrated: item.acf?.castrated === 'yes' ? 'yes' : '',
    age: item.acf?.age || '',
    gender: mapGender(item.acf?.gender || ''),
    title: item.acf?.['pet_name'] || item.title?.rendered,
    type: mapType(item.acf?.['type'] || ''),
    size: mapSize(item.acf?.['size'] || ''),
    photos: (item.acf?.images || []).map(image => image.sizes?.large || image),
    link: `/${item.slug}`,
    breed: item.acf?.breed ? [ item.acf?.breed ] : []
  })
}
