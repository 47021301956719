// @flow

import { pm, serializers, queryFormFactory } from '@fcse/param-converter'

export const PAGE_SIZE_OPTIONS = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '30', label: '30' },
  { value: '50', label: '50' }
]

export const ANIMAL_TYPE_DOGS = 'dog'
export const ANIMAL_TYPE_CATS = 'cat'
export const ANIMAL_TYPE_BIRDS = 'bird'
export const ANIMAL_TYPE_SMALL = 'small'
export const ANIMAL_TYPE_LOST = 'lost'

const config = {
  type: pm('type', serializers.string),
  query: pm('query', serializers.string),
  gender: pm('gender', serializers.string),
  color: pm('color', serializers.string),
  breed: pm('breed', serializers.string),
  size: pm('size', serializers.string),
  agePhase: pm('agePhase', serializers.string),
  place: pm('place', serializers.string),
  skin: pm('skin', serializers.string),
  shelter: pm('shelter', serializers.string),
  sortBy: pm('sortBy', serializers.string),
  perPage: pm('perPage', serializers.number),
  page: pm('page', serializers.number)
}

export const defaultValues = {
  type: '',
  query: '',
  color: '',
  breed: '',
  gender: '',
  size: '',
  agePhase: '',
  place: '',
  skin: '',
  shelter: '',
  sortBy: 'new',
  page: 0,
  perPage: 10
}

const { Form, FormWithQuery, withQuery, createQueryString }
  = queryFormFactory('animalSearchNS', () => defaultValues, config, null, 'as') // in case we need a separate namespace


export { Form, FormWithQuery, withQuery, createQueryString }
