// @flow

export const createPetSearchParams = (search: Object) => {
  let idx = 0

  /* eslint-disable-next-line */
  const params = {}
  if (search.gender) {
    params[`filter[meta_query][${idx}][key]`] = 'gender'
    params[`filter[meta_query][${idx}][value]`] = search.gender
    idx++
  }
  if (search.size) {
    params[`filter[meta_query][${idx}][key]`] = 'size'
    params[`filter[meta_query][${idx}][value]`] = search.size
    idx++
  }
  if (search.agePhase) {
    params[`filter[meta_query][${idx}][key]`] = 'age_phase'
    params[`filter[meta_query][${idx}][value]`] = search.agePhase
    idx++
  }
  if (search.type) {
    params[`filter[meta_query][${idx}][key]`] = 'type'
    params[`filter[meta_query][${idx}][value]`] = search.type
    idx++
  }
  if (search.breed) {
    params[`filter[meta_query][${idx}][key]`] = 'breed'
    params[`filter[meta_query][${idx}][value]`] = search.breed
    idx++
  }

  return params
}

export const createGeneralParams = (search: Object) => {
  const params = {}
  if (search.query) {
    params['search'] = search.query
  }
  if (search.sortBy) {
    if (search.sortBy === 'new') {
      params['order'] = 'desc'
      params['orderby'] = 'date'
    } else if (search.sortBy === 'alphabet') {
      params['order'] = 'asc'
      params['orderby'] = 'title'
    }
  }
  if (search.perPage) {
    params['per_page'] = search.perPage
  }
  if (search.page) {
    params['page'] = search.page + 1
  }
  return params
}
