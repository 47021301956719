import React from 'react'
import { applyStyling } from '../../lib/styling'
import Button from '../../components/basic/Button'
import { scrollToTarget } from '../../helpers/scroll'
import baseStyles from './BackToTop.scss'
import useWindowScroll from '../../hooks/useWindowScroll'

type Props = {
  styles: Object
}

const SCROLL_THRESHOLD = 50 // px

const scrollToTop = () => scrollToTarget(0)

const BackToTop = ({ styles }: Props) => {
  const scrollState = useWindowScroll()

  return (
    scrollState.y > SCROLL_THRESHOLD ?
      <Button
        styles={styles.root}
        onClick={scrollToTop}
        icon="icon-up"
      /> : null
  )
}

export default applyStyling(baseStyles)(BackToTop)
