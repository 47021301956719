// @flow
/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import { Container, Row, Col } from '../../components/basic/Grid'
import Button from '../../components/basic/Button'
import Teaser from '../../components/Teaser'
import baseStyles from './NewsTeasers.scss'
import _get from 'lodash/get'
import { Link } from 'gatsby-plugin-intl'

type Props = {
  styles: Object,
  data: Object
}

const NewsTeasers = ({ styles, data }: Props) => {
  const imageSquare = _get(data, 'background')
  const imageSquare2x = _get(data, 'background')
  const imageLandscape2x = _get(data, 'background')
  const imageLandscape3x = _get(data, 'background')
  const imageLandscape4x = _get(data, 'background')
  const imageLandscape = _get(data, 'background')
  const newsUrl = _get(data, 'link.url')
  return (
    <div className={styles.root}>
      <div className={styles.imageWrap}>
        <picture className={styles.imageContainer}>
          <source
            media={'(min-width: 2049px)'}
            srcSet={`${imageLandscape3x} 3x, ${imageLandscape4x} 4x`}
          />
          <source
            media={'(min-width: 1025px)'}
            srcSet={`${imageLandscape2x} 2x, ${imageLandscape3x} 3x`}
          />
          <source
            media={'(min-width: 768px)'}
            srcSet={`${imageLandscape} 1x, ${imageLandscape2x} 2x`}
          />
          <source
            media={'(max-width: 767px)'}
            srcSet={`${imageSquare} 1x, ${imageSquare2x} 2x`}
          />
          <img className={styles.image} src={imageLandscape} />
        </picture>
      </div>
      <Container className={styles.container}>
        <Row className={styles.row}>
          <Col md="6" lg="4" className={styles.col}>
            <div className={styles.textWrap}>
              <h2 className={styles.title}>{_get(data, 'title')}</h2>
              <div className={styles.info}>{_get(data, 'slogan')}</div>
              <p className={styles.text} dangerouslySetInnerHTML={{ __html: _get(data, 'content') }}/>
              {newsUrl &&
              <Link to={newsUrl}>
                <Button iconRight="icon-right" styles={styles.moreBtn}>mehr erfahren</Button>
              </Link>
              }
            </div>
          </Col>
          {(_get(data, 'items') || []).map((item, key) => (
            <Col md="6" lg="4" className={styles.col} key={key}>
              <Teaser
                styles={styles.teaser}
                link={_get(item, 'link')}
                image={_get(item, 'image')}
                title={_get(item, 'title')}
                info={_get(item, 'type')}
                date={_get(item, 'date')}
                textComponent={<div dangerouslySetInnerHTML={{ __html: _get(item, 'content') }}/>}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default applyStyling(baseStyles)(NewsTeasers)
