// @flow
/* eslint-disable max-len */
import React from 'react'
import { applyStyling } from '../../lib/styling'
import { Container, Row, Col } from '../../components/basic/Grid'
import Teaser from '../../components/Teaser'
import baseStyles from './ProjectsTeasers.scss'
import _get from 'lodash/get'

type Props = {
  styles: Object,
  data: Object
}

const ProjectsTeasers = ({ styles, data }: Props) => (
  <Container className={styles.root}>
    <h2 className={styles.title}>
      {_get(data, 'title')}
    </h2>
    <Row className={styles.row}>
      {(_get(data, 'items') || []).map((item, key) => (
        <Col md="6" lg="4" className={styles.col} key={key}>
          <Teaser
            styles={styles.teaser}
            link={_get(item, 'link.url')}
            image={_get(item, 'image')}
            title={_get(item, 'title')}
            info={_get(item, 'type')}
            textComponent={<div dangerouslySetInnerHTML={{ __html: _get(item, 'content') }}></div>}
          />
        </Col>
      ))}
    </Row>
  </Container>
)

export default applyStyling(baseStyles)(ProjectsTeasers)
