// @flow

import React from 'react'
import classNames from 'classnames'
import { applyStyling } from '../../lib/styling'
import { Container } from '../../components/basic/Grid'
import Device from '../../components/basic/Device'
import baseStyles from './HeroTeaser.scss'

type Props = {
  styles: Object,
  imageLandscape: Object,
  imageLandscape2x: Object,
  imageLandscape3x: Object,
  imageLandscape4x: Object,
  title: String,
  subtitle: Object
}

const getLandscapeImages = (props: Object) => {
  return {
    imageLandscape: props.imageLandscape,
    imageLandscape2x: props.imageLandscape2x || props.imageLandscape,
    imageLandscape3x: props.imageLandscape3x || props.imageLandscape2x || props.imageLandscape,
    imageLandscape4x: props.imageLandscape4x || props.imageLandscape3x || props.imageLandscape2x ||
      props.imageLandscape
  }
}

const HeroTeaser = ({
  styles, title, subtitle, ...rest
}: Props) => {
  const { imageLandscape, imageLandscape2x, imageLandscape3x, imageLandscape4x } = getLandscapeImages(rest)
  if (!imageLandscape && !title && !subtitle) {
    return null
  }
  return (
    <div className={styles.root}>
      {imageLandscape && imageLandscape2x && imageLandscape3x && imageLandscape4x &&
      <div>
        <Device tablet desktop>
          <div className={styles.imageWrap}>
            <picture className={styles.imageContainer}>
              <source
                media={'(min-width: 2049px)'}
                srcSet={`${imageLandscape3x} 3x, ${imageLandscape4x} 4x`}
              />
              <source
                media={'(min-width: 1025px)'}
                srcSet={`${imageLandscape2x} 2x, ${imageLandscape3x} 3x`}
              />
              <source
                media={'(min-width: 768px)'}
                srcSet={`${imageLandscape} 1x, ${imageLandscape2x} 2x`}
              />
              <img className={styles.image} src={imageLandscape} />
            </picture>
          </div>
        </Device>
      </div>}
      <div className={styles.headlines}>
        <Container>
          <div className={styles.titleWrap}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }}/>
          </div>
          <Device tablet desktop>
            <a
              href="https://www.facebook.com/tierheim.d/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.fbLink}
            >
              <span className={classNames(styles.fbLinkIcon, 'icon-facebook')}/>
            </a>
          </Device>
        </Container>
      </div>
    </div>
  )
}
export default applyStyling(baseStyles)(HeroTeaser)
