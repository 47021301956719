// @flow
import _get from 'lodash/get'

export const mapTextAndImage = (data: Object) => {
  return (_get(data, 'text_and_image') || []).map(item => ({
    title: _get(item, 'title'),
    text: _get(item, 'content'),
    image: _get(item, 'image'),
    link: _get(item, 'link'),
    isReversed: _get(item, 'image_position') === 'right'
  }))
}

export const mapWideNews2Items = (data: Object) => {
  return ({
    title: _get(data, 'title'),
    slogan: _get(data, 'slogan'),
    content: _get(data, 'content'),
    background: _get(data, 'background'),
    link: _get(data, 'link'),
    items: (_get(data, 'items') || []).map(item => ({
      title: _get(item, 'title'),
      type: _get(item, 'type'),
      date: _get(item, 'date'),
      content: _get(item, 'content'),
      image: _get(item, 'image'),
      link: _get(item, 'link')
    }))
  })
}

export const mapThreeItems = (data: Object) => {
  return ({
    title: _get(data, 'title'),
    items: (_get(data, 'items') || []).map(item => ({
      title: _get(item, 'title'),
      type: _get(item, 'item_type'),
      content: _get(item, 'content'),
      image: _get(item, 'image'),
      link: _get(item, 'link')
    }))
  })
}

export const mapWideTeaser = (data: Object) => {
  return ({
    title: _get(data, 'title'),
    background: _get(data, 'background'),
    content: _get(data, 'content'),
    link: _get(data, 'link')
  })
}
